.tableUpdateControlsContainer {
    background-color: #ffffff;
    padding: 1.25rem 1.5625rem 0 1.3875rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: auto;
}

.tableUpdateControlsContainer h2 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 2rem;
    padding-left: 0.125rem;
}

.tableUpdateControlsParameters {
    width: auto;
    display: grid;
    grid-template-columns: repeat(3, 16rem);
    grid-template-rows: auto;
    gap: 0.5rem;
}

.tableUpdateControls {
    margin-left: 2rem;
    align-items: top;
    padding: 0.25rem 1rem 0.25rem 1rem;
    background-color: #ffffff;
    border-radius: 0.125rem;
    background-color: hsla(220, 50%, 20%, 0.025);
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    /* overflow-x: auto; */
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tableUpdateControls .actionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tableUpdateControls .actionsContainer:hover {
    cursor: pointer;
}

.tableUpdateControls .actionsContainer:focus, 
.tableUpdateControls .actionsContainer:focus-visible{
    border: 1px solid hsla(185, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(185, 80%, 50%, 0.25);
    transition: opacity var(--speed) ease-in-out;
}

.tableUpdateControls .actionsContainer .expandChevronClosed,
.tableUpdateControls .actionsContainer .expandChevronOpen {
    background-color: transparent;
    background-size: 1.25rem 1.25rem;
    width: 2rem;
    height: 2rem;
}

.tableUpdateControls .actionsContainer .expandChevronClosed{ 
    background: url('../../../../img/chevron-down.svg') center no-repeat;
}
.tableUpdateControls .actionsContainer .expandChevronOpen {
    background: url('../../../../img/chevron-up.svg') center no-repeat;
}

.tableUpdateControls .buttonsContainer {
    display: grid;
    grid-template-columns: 2fr 2fr;
    align-self: flex-end;
    gap: 0.5rem;
    /* position: relative; */
}

.tableUpdateControls .resetParametersButton,
.tableUpdateControls .cascadeChangesButton {
    border: 1px solid #d2d6e1;
    width: 3rem;
    height: 3rem;
    border-radius: 2px;
    background-color: #ffffff;
    padding: 0;
}

.tableUpdateControls .resetParametersButton {
    background: url('../../../../img/arrow-repeat-dark.svg') center center no-repeat;
}

.tableUpdateControls .cascadeChangesButton {
    background: url('../../../../img/diagram-3.svg') center center no-repeat;
}

.tableUpdateControls button:hover {
    cursor: pointer;
}

.tableUpdateControls .resetParametersButton:hover,
.tableUpdateControls .resetParametersButton:focus,  
.tableUpdateControls .resetParametersButton:focus-visible, 
.tableUpdateControls .cascadeChangesButton:hover,
.tableUpdateControls .cascadeChangesButton:focus-visible,
.tableUpdateControls .cascadeChangesButton:focus {
    background-size: 1.75rem;
}