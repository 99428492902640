
.container {
    margin: 0.5rem 2rem;
    text-align: center;
}

.status {
    width: 100%;
    height: 3rem;
    border: solid 1px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.success {
    composes: status;
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.error {
    composes: status;
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}

.warning {
    composes: status;
    background-color: #fff3cd;
    color: #856404;
    border-color: #ffeeba;

}

.textWrapper {
    width: auto;
    padding-left: 2rem;
}

.buttonWrapper {
    width: auto;
    padding-right: 2rem;
}

.closeButton {
    background-image: url('../../../img/x.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 1rem 1rem;
    width: 2rem;
    height: 2rem;
    opacity: 0.5;
    transition: background-size ease-in-out;
}

.closeButton:hover {
    cursor: pointer;
    background-size: 1.25rem 1.25rem;
}
