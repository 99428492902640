.ratesEditRow:hover{
    background-color: transparent;
}

.ratesEditRow table {
    background-color: hsla(220, 50%, 20%, 0.025);
    border-radius: 0.125rem;
    height: auto;
    width: auto;
    border-spacing: 0;
    white-space: nowrap;
    padding: 0.75rem 0.5rem 0.75rem;
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    margin-bottom: 0.25rem;
    margin-top: -0.125rem;
}

.ratesEditRow table td, .ratesEditRow table th {
    padding: 0 0.5rem 0 0.5rem;
    font-weight: normal;
    height: 2rem;
}

.ratesEditRow table thead h3 {
    font-weight: 600;
    font-size: 1.25rem;
    color: black;
    padding-bottom: 0.75rem;
    text-align: left;
}
.ratesEditRow table thead h3 span {
    font-weight: 400;
    font-size: 0.875rem;
    right: 0.375rem;
    top: 0.25rem;
    color: hsla(220, 50%, 20%, 0.5);
    float: right;
}

.editButton {
    background-image: url('../../../img/plus.svg');
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 2px;
    cursor: pointer;
    transition: all var(--speed) ease-in-out;
    float: right;
}

.editButton:disabled:hover {
    transition: none;
    background-size: initial;
}

.saveButton {
    background-image: url('../../../img/check.svg');
}

.cancelButton {
    background-image: url('../../../img/x.svg');
}

.cancelButton, .saveButton {
    width: 2rem;
    height: 2rem;
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem;
    border-radius: 2px;
    cursor: pointer;
    transition: all var(--speed) ease-in-out;
}

.editButton:hover, .cancelButton:hover, .saveButton:hover {
    background-size: 1.25rem;
}


.labelMessage {
    font-size: 0.75rem;
    font-style: italic;
    color: gray;
}