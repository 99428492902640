.outerContainer {
    background-color: #ffffff;
    padding: 1.25rem 1.5625rem 0 1.3875rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
   
}

.innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5rem;
}

.mainButtonsContainer {
    display: flex;
    gap: 1rem;
}

.smallButtonBase {
    border: 1px solid #d2d6e1;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2px;
    background-color: #ffffff;
    padding: 0;
}

.addButton {
    composes: smallButtonBase;
    background: url('../../../../img/plus.svg') no-repeat center center;
}

.addButton:hover,
.addButton:focus-visible {
    background: url('../../../../img/plus.svg') no-repeat center center;
    background-size: 1.75rem;
    cursor: pointer;
}

.saveButton {
    composes: smallButtonBase;
    background: url('../../../../img/check.svg') no-repeat center center;
}

.saveButton:hover, 
.saveButton:focus-visible {
    background: url('../../../../img/check.svg') no-repeat center center;
    background-size: 1.75rem;
    cursor: pointer;
}

.cancelButton {
    composes: smallButtonBase;
    background: url('../../../../img/x.svg') no-repeat center center;
}

.cancelButton:hover, 
.cancelButton:focus-visible {
    background: url('../../../../img/x.svg') no-repeat center center;
    background-size: 1.75rem;
    cursor: pointer;
}
