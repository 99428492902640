.Label {
    font-size: 0.875rem;
    line-height: 2rem;
    margin-right: 0.5rem;
    display: inline-block;
}

.Label strong {
    font-size: 0.875rem;
    line-height: 2rem;
}