*,
*:before,
*:after {
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.333rem;
    font-family: --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    word-wrap: break-word;
    /* user-select: none; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;

    --blue-15: hsla(220, 25%, 32%, 0.15);
    --blue-20: hsla(220, 25%, 32%, 0.2);
    --blue-50: hsla(220, 25%, 32%, 0.5);
    --blue-80: hsla(220, 25%, 32%, 0.8);
    --blue-100: hsla(220, 25%, 32%, 1);

    --brightblue-12: hsla(220, 50%, 40%, 0.12);
    --brightblue-12-fill: hsla(220, 32%, 93%, 1);
    --brightblue-24: hsla(220, 50%, 40%, 0.24);

    --white: hsla(0, 0%, 100%, 1);
    --black: hsla(220, 0%, 0%, 0.85);

    --speed: 150ms;
}

* {
    padding: 0;
    margin: 0;
}

strong {
    font-weight: 600;
}

button,
input,
select,
textarea {
    outline: none;
    border: none;
    /* user-select: initial; */
}
ol,
ul {
    list-style-type: none;
}

body {
    background-color: var(--brightblue-12-fill);
}

.container {
    width: 100%;
    margin-top: 5rem;
    padding-bottom: 2rem;
}

.content {
    padding: 2rem 4rem 0 4rem;
    margin-left: 330px;
}

.content-wide {
    padding: 2rem 4rem 0 4rem;
    margin-left: 0;
}

.dialog .button {
    height: 3rem;
    background-color: #ffffff;
    border-radius: 0.1875rem;
    cursor: pointer;
    color: black;
    font-weight: 400;
    position: relative;
    padding: 0 1rem;
    border: 1px solid hsla(0, 0%, 0%, 0.2);
    transition: all var(--speed) ease-in-out;
}

.dialog .button:hover {
    background-color: hsla(0, 0%, 0%, 0.1);
}

.dialog .button.secondary {
    opacity: 0.7;
}

.loading {
    height: 3rem;
    width: 3rem;
    display: grid;
    justify-self: center;
    align-self: center;
    background: url(../img/rings.svg) no-repeat center center;
    background-size: auto;
}

.error {
    height: 3rem;
    display: grid;
    justify-self: center;
    align-self: center;
    background: url(../img/exclamation-triangle-fill.svg) no-repeat center top;
    background-size: 2.5rem;
}

.screen {
    background-image: linear-gradient(180deg, #3366cc 0%, #1f4ead 100%);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    justify-content: center;
    align-content: center;
    padding: 1rem;
}

.dialog {
    border-radius: 0.5rem;
    background-color: var(--white);
    color: var(--black);
    box-shadow: 0 1rem 2rem hsla(220, 50%, 20%, 1);
    padding: 1rem;
    text-align: center;
}

.dialog div {
    padding: 0.5rem 0;
    width: 100%;
}

.dialog div:first-child {
    padding-top: 0;
}

.dialog div:first-child h1 {
    font-size: 1.5rem;
    text-align: center;
    height: 5rem;
    line-height: 1.333;
    padding: 0;
}

.dialog div:nth-child(2) {
    margin-bottom: 1rem;
}

.dialog div:last-child {
    padding: 2.5rem 0 0 0;
}

.dialog div * {
    width: 100%;
}

.dialog .btn-small {
    width: auto;
    height: 2.5rem;
}

.dialog .errorMessage {
    padding: 0.5rem 1rem;
    margin: 1.25rem 0 2rem;
    background-color: hsla(0, 60%, 60%, 1);
    color: white;
    font-weight: 600;
    border-radius: 0.125rem;
}

.main-content {
    margin-top: 1.5rem;
}

.zorgverkeer {
    height: 4.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    pointer-events: none;
}

.zorgverkeer img {
    height: inherit;
}

.welcome-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-flow: row nowrap;
    margin-bottom: 2rem;
}

.welcome-logos img {
    width: 33.333%;
}

.sodaconsult {
    height: 6rem;
    margin: 0 1rem 0 0;
    padding: 1.5rem 0;
    /* border: 1px solid red; */
}

.sodaverkeer {
    height: 6rem;
    padding: 0.5rem 0;
    /* border: 1px solid red; */
}

.zorgverkeer-start {
    height: 6rem;
    margin: 0 0 0 1rem;
    padding-right: 0.25rem;
    /* border: 1px solid red; */
}

@media only screen and (max-width: 1300px) {
    .welcome-logos {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        pointer-events: none;
    }
    .welcome-logos img {
        width: 100%;
    }
    .sodaconsult,
    .sodaverkeer,
    .zorgverkeer-start {
        margin: 0 1rem 0.5rem;
    }
    .zorgverkeer-start {
        position: relative;
    }
}

.welcome-message {
    padding: 1rem 0 2rem 0;
}

.mr1 {
    margin-right: 1rem;
}

.ml1 {
    margin-left: 1rem;
}

.settings p {
    margin-bottom: 1.333rem;
}

.profile-img {
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
}

.aanbieder li {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-bottom: 0.25rem;
}

.aanbieder li:last-child {
    padding-bottom: 0;
}

.aanbieder li button {
    width: 100%;
    margin-left: 0.5rem;
}

.aanbieder li input {
    height: 1.25rem;
    width: 1.25rem;
}

.info-color {
    position: relative;
    color: hsla(180, 60%, 40%, 1);
    font-weight: 500;
    text-align: left;
    display: inline-block;
    border-radius: 0.125rem;
}

.warning-color {
    position: relative;
    color: hsla(30, 60%, 60%, 1);
    font-weight: 500;
    text-align: left;
    display: inline-block;
    border-radius: 0.125rem;
}

.error-color {
    position: relative;
    color: hsla(0, 60%, 60%, 1);
    font-weight: 500;
    text-align: left;
    display: inline-block;
    border-radius: 0.125rem;
}

.client-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2rem;
}

.client-buttons .button {
    height: 3rem;
    cursor: pointer;
    border-radius: 0;
    font-size: 1rem;
    line-height: 2rem;
    flex-basis: 100%;
    padding: 0 1rem;
    color: white;
    font-weight: 600;
    text-shadow: 0 1px 1.5px hsla(0, 0%, 0%, 0.3);
    background: linear-gradient(180deg, #17bfcf 0%, #1fa2ad 100%);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    white-space: nowrap;
    position: relative;
    margin-right: 1px;
}

.client-buttons .button:first-of-type {
    margin-left: 0;
}

.client-buttons .button:last-of-type {
    margin-right: 0;
}

.client-buttons .button aside {
    position: absolute;
    min-width: 1.125rem;
    height: 1.125rem;
    color: white;
    background-color: red;
    font-size: 0.75rem;
    text-align: center;
    line-height: 1.125rem;
    padding: 0 0.125rem;
    right: 0;
    top: 0;
    pointer-events: none;
    font-weight: 900;
}
.client-buttons .button label {
    pointer-events: none;
}

.client-buttons .button.has-signal {
    padding-right: 2rem;
}

.client-buttons .button:first-of-type {
    border-radius: 0.25rem 0 0 0.25rem;
}

.client-buttons .button:last-of-type {
    border-radius: 0 0.25rem 0.25rem 0;
}

.client-buttons .button:hover {
    background: linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
}

.client-buttons .button.active {
    opacity: 1;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    margin-right: 0.125rem;
    pointer-events: none;
}

.icon-client {
    background: url(../img/person-white.svg) no-repeat left center;
    background-size: 1.5rem;
}

.icon-vot {
    background: url(../img/file-arrow-up.svg) no-repeat left center;
    background-size: 1.5rem;
}

.icon-toewijzing {
    background: url(../img/file-arrow-down.svg) no-repeat left center;
    background-size: 1.5rem;
}

.icon-vow {
    background: url(../img/file-arrow-up.svg) no-repeat left center;
    background-size: 1.5rem;
}

.icon--download {
    background-color: black;
    mask: url(../img/cloud-arrow-down.svg) no-repeat center center;
    width: 1rem;
    height: 1rem;
}

.icon--upload {
    background-color: black;
    mask: url(../img/cloud-arrow-up.svg) no-repeat center center;
    width: 1rem;
    height: 1rem;
}
.icon--upload-pending {
    background-color: black;
    mask: url(../img/arrow-up-short.svg) no-repeat center center;
    width: 1rem;
    height: 1rem;
}

.client-data .button:first-of-type {
    margin-left: 0;
}

.client-data .button:last-of-type {
    margin-right: 0;
}

nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5rem;
    position: fixed;
    top: 0px;
    left: 0px;
    padding: 0;
    z-index: 4;
    background-color: #3366cc;
    background-image: linear-gradient(180deg, #3366cc 0%, #1f4ead 100%);
    box-shadow: 0 0 0.75rem 0 hsla(220, 50%, 40%, 0.75);
}

nav .logo a {
    font-size: 1.75rem;
    color: white;
    font-weight: 700;
    text-decoration: none;
    display: block;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 0 0 0 1.5rem;
    text-shadow: 0 1px 1.5px hsla(0, 0%, 0%, 0.3);
}

nav .logo a span {
    position: relative;
    top: -0.25rem;
}

nav .logo a span:first-of-type {
    font-size: 1rem;
    opacity: 0.7;
}

nav .logo a span:last-of-type {
    font-size: inherit;
    white-space: nowrap;
}

nav ul {
    list-style-type: none;
    display: flex;
    flex-flow: row nowrap;
}

nav ul li {
    display: inline-block;
    line-height: 5rem;
    color: hsla(0, 0%, 100%, 0.5);
    font-weight: 700;
    transition: all var(--speed) ease-in-out;
    display: flex;
}

nav ul li a {
    padding: 0 1.5rem;
    flex-basis: 100%;
    color: inherit;
    text-decoration: none;
    line-height: inherit;
}

nav ul li.active {
    color: hsla(0, 0%, 100%, 1);
    text-shadow: 0 1px 1.5px hsla(0, 0%, 0%, 0.3);
}

nav ul li:hover,
nav ul li.user:hover,
nav ul li.notify:hover {
    color: hsla(0, 0%, 100%, 1);
    cursor: pointer;
    background-color: hsla(0, 0%, 0%, 0.2);
}

nav ul li.user {
    background: url(../img/person-nav.svg) no-repeat 1.25rem center;
    background-size: 1.5rem;
    padding-left: 3rem;
    position: relative;
}

nav ul li.notify {
    background: url(../img/bell.svg) no-repeat 1.25rem center;
    background-size: 1.375rem;
    padding-left: 1.5rem;
    position: relative;
}

nav ul li:last-of-type {
    padding-right: 2rem;
}

nav ul li.notify aside {
    position: absolute;
    min-width: 18px;
    height: 18px;
    color: white;
    background-color: red;
    border-radius: 0.125rem;
    font-size: 0.85rem;
    text-align: center;
    line-height: 18px;
    left: 1.8125rem;
    top: 1.25rem;
    animation: Pulsate 4s ease-in-out infinite;
    box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.7);
    font-weight: 700;
    letter-spacing: -1px;
    padding: 0 0.25rem 0 0.25rem;
}

nav ul li.report {
    position: relative;
}

nav ul li.user:hover .sub_menu,
nav ul li.notify:hover .sub_menu,
nav ul li.report:hover .sub_menu,
nav ul li.logo:hover .sub_menu {
    display: block;
}

.sub_menu {
    width: 100%;
    position: absolute;
    display: none;
    z-index: 1;
    left: 0;
    top: 5rem;
}

.sub_menu ul {
    background-color: hsla(220, 0%, 100%, 0.95);
    flex-flow: column nowrap;
    box-shadow: 0 2px 3px hsla(220, 50%, 20%, 0.5);
    border-radius: 2px;
    position: relative;
    top: -1px;
}

.sub_menu ul li {
    margin: 0;
    padding: 0;
    line-height: inherit;
    color: black;
    font-weight: 400;
    text-shadow: none;
    width: 100%;
    display: flex;
}

.sub_menu ul li:first-of-type {
    border-radius: 2px 2px 0 0;
}

.sub_menu ul li:last-of-type {
    border-radius: 0 0 2px 2px;
}

.sub_menu ul li:hover {
    background-color: #3366cc;
}

.sub_menu ul li a {
    padding: 0.625rem 0.5rem 0.625rem 1.5rem;
    flex-basis: 100%;
    color: inherit;
    text-decoration: none;
    font-size: 1rem;
}

.notify_menu {
    width: 300px;
    left: unset;
    right: 0;
}

.logo_menu {
    line-height: 1.375rem;
    width: auto;
}

.logo_menu ul li a {
    font-weight: 400;
    text-shadow: none;
}

.report_menu {
    width: 300px;
}

@media only screen and (max-width: 1000px) {
    .sub_menu {
        left: unset;
        left: 0;
    }
    .report_menu {
        right: unset;
        left: 0;
    }
}

@keyframes Pulsate {
    from {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

body.react-confirm-alert-body-element {
    overflow: auto;
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: hsla(220, 50%, 20%, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-confirm-alert-body {
    border-radius: 0.1875rem;
    background-color: var(--white);
    box-shadow: 0 1rem 2rem hsla(220, 50%, 20%, 1);
    color: var(--black);
    width: 50vw;
    text-align: left;
    background: #fff;
    border-radius: 2px;
    padding: 0 1.5rem;
    border: 5px solid #d2d6e1;
}

.react-confirm-alert-body > h1 {
    font-size: 1.5rem;
    text-align: center;
    line-height: 5rem;
}

.react-confirm-alert-button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
}

.react-confirm-alert-button-group > button {
    background: linear-gradient(180deg, #17bfcf 0%, #1fa2ad 100%);
    border-radius: 0.1875rem;
    cursor: pointer;
    line-height: 2.5rem;
    padding: 0 2rem;
    color: white;
    font-weight: 600;
    margin: 0 0.25rem;
    text-shadow: 0 1px 1.5px hsla(0, 0%, 0%, 0.3);
}

.react-confirm-alert-button-group > button:hover {
    background: linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
}

.small {
    padding: 0.5rem 1rem;
    line-height: 1rem;
    margin: 0;
}

.retourcode {
    margin-bottom: 1rem;
}

.retourcode:last-of-type {
    margin-bottom: 0;
}

.legend {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1rem;
}

.legend div {
    margin-bottom: 1rem;
}

.legend div div {
    font-size: 0.875rem;
    line-height: 1.125rem;
}

.legend .active {
}

.legend .inactive {
    opacity: 0.5;
}
