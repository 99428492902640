.model-backdrop {
    z-index: 7;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: hsla(220, 50%, 20%, 0.7);
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.model-backdrop form {
    border-radius: 0.1875rem;
    background-color: var(--white);
    color: var(--black);
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-items: flex-start;
    max-width: 75vw;
    box-shadow: 0 1rem 2rem hsla(220, 50%, 20%, 1);
    max-width: 1000px;
    cursor: default;
    max-height: 100%;
    overflow: auto;
    position: relative;
    border: 5px solid #d2d6e1;
}

form .close {
    position: absolute;
    right: 0.125rem;
    top: 0.125rem;
    width: 2rem;
    height: 2rem;
    background: url(../img/x.svg) no-repeat center;
    background-size: 1.75rem;
    opacity: 0.5;
    cursor: pointer;
}

form .close:hover {
    background-size: 2rem;
    opacity: 1;
    transition: opacity 300ms;
}

form header {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

form header h1 {
    font-size: 1.5rem;
    line-height: 1.75rem;
}

form main {
    padding: 0 1.5rem;
    overflow-y: auto;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    max-height: 70vh;
    width: 100%;
}

.exception {
    max-height: fit-content;
}

form label {
    margin-bottom: 1rem;
    width: 100%;
    padding: 0 0.5rem;
}

form label span:first-of-type {
    display: block;
    margin-top: 2px;
    padding-left: 0.575rem;
}

form label span:last-of-type {
    display: block;
    margin-top: 2px;
    padding-left: 0.575rem;
}

form label span:nth-of-type(2) {
    font-size: 0.875rem;
    opacity: 0.5;
    font-style: italic;
}

.autorisaties {
    white-space: normal;
}

.autorisaties span {
    display: inline-block;
    font-size: 0.75rem;
    opacity: 1;
    color: hsla(220, 50%, 20%, 0.7);
    padding: 0 0.25rem 0.0625rem 1.125rem;
    border-radius: 0.25rem;
    font-weight: 500;
    margin-bottom: 0.1875rem;
    background: url(../img/person-fill.svg) no-repeat 0.25rem center, hsla(220, 50%, 20%, 0.075);
    background-size: 0.75rem, auto;
    cursor: help;
}

form input,
form select,
form textarea {
    border-radius: 2px;
    font-size: 1rem;
    line-height: 2rem;
    padding: 0 0.5rem 0.125rem;
    transition: all var(--speed) ease-in-out;
    border: 1px solid hsla(220, 50%, 20%, 0.3);
    background-color: #ffffff;
    width: 100%;
    height: 2.125rem;
    cursor: text;
}

form .disabled {
    color: rgb(109, 109, 109)
}

form select {
    padding-right: 1rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url(../img/caret-down-fill.svg) no-repeat right center;
    background-size: 1.25rem;
    background-color: #ffffff;
    cursor: default;
}

select::-ms-expand {
    display: none;
}

form input::placeholder,
form select::placeholder,
form textarea::placeholder {
    transition: opacity var(--speed) ease-in-out;
    opacity: 1;
    color: hsla(220, 50%, 20%, 0.3);
}

form input:focus::placeholder,
form select:focus::placeholder,
form textarea:focus::placeholder {
    opacity: 0;
}

form input:focus,
form select:focus,
form textarea:focus {
    border: 1px solid hsla(185, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(185, 80%, 50%, 0.25);
    background-color: #ffffff;
}

form input:disabled,
form select:disabled,
form textarea:disabled {
    border: 1px solid hsla(220, 50%, 20%, 0.1);
    pointer-events: none;
    height: 2.25rem;
}

form select:disabled {
    background: none;
}

form label.col2, form span.col2 {
    flex-basis: 50%;
    padding: 0 0.5rem;
}

form label.col3 {
    flex-basis: 33.333%;
    padding: 0 0.5rem;
}

form label.col4 {
    flex-basis: 25%;
    padding: 0 0.5rem;
}

form label.col5 {
    flex-basis: 20%;
    padding: 0 0.5rem;
}

form label.col2-break {
    margin-right: 50%;
}

form label.col3-break {
    margin-right: 66.666%;
}

form label.col4-break {
    margin-right: 75%;
}

form label.break-25 {
    margin-right: 25%;
}

form label.break-33 {
    margin-right: 33.333%;
}

form label.break-50 {
    margin-right: 50%;
}

form label.break-66 {
    margin-right: 66.666%;
}

form label.break-75 {
    margin-right: 75%;
}

form label.break-80 {
    margin-right: 80%;
}

form textarea {
    line-height: 1.333rem;
    padding-top: 0.25rem;
    flex-basis: 100%;
    min-height: 5.333rem;
}

form textarea:disabled {
    border: transparent;
}

form footer {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1rem 1.75rem;
}

form button {
    background: linear-gradient(180deg, #17bfcf 0%, #1fa2ad 100%);
    background-size: 100%;
    border-radius: 0.1875rem;
    cursor: pointer;
    transition: all var(--speed) ease-in-out;
    line-height: 2.5rem;
    padding: 0 2rem 0.125rem;
    color: white;
    font-weight: 600;
    margin: 0.25rem;
    text-shadow: 0 1px 1.5px hsla(0, 0%, 0%, 0.3);
}

.no-margin {
    margin: 0;
}

form button:hover,
form button:focus-visible {
    background: linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
    background-size: 100%;
}

form button.secondary {
    background: linear-gradient(180deg, #17bfcf 0%, #1fa2ad 100%);
    opacity: 0.7;
}

form button.secondary:hover,
form button.secondary:focus-visible {
    background: linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
    opacity: 0.7;
}

.valid {
    box-shadow: inset 0 0 0 2px hsla(100, 0%, 100%, 1);
    background-color: hsla(100, 50%, 50%, 0.2);
    border-color: hsla(100, 50%, 50%, 0.6);
}

.invalid {
    box-shadow: inset 0 0 0 2px hsla(0, 0%, 100%, 1);
    background-color: hsla(0, 50%, 50%, 0.2);
    border-color: hsla(0, 50%, 50%, 0.6);
}

button:disabled {
    cursor: not-allowed;
    opacity: 0.3;
}

.mt2 {
    margin-top: 2rem;
}

@media only screen and (max-width: 960px) {
    form label.col2,
    form label.col3,
    form label.col4 {
        flex-basis: 100%;
        min-width: 100%;
    }
}

form input [type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

form input [type='number']::-webkit-outer-spin-button,
form input [type='number']::-webkit-inner-spin-button {
    display: none;
    margin: 0;
}
