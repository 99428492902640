.dataTable {
    width: 100%;
    border-spacing: 0;
    background-color: #ffffff;
    padding: 0.875rem 1rem;
    white-space: nowrap;
}

.outerContainer {
    overflow-x: scroll;
}

.innerContainer {
    position: relative;
    display: inline-block;
    min-width: 100%;
}

.hintArrow {
    position: absolute;
    width: 2rem;
    top: 1.8125rem;
    bottom: 1.8125rem;
    background-size: 2rem, 2rem;
    opacity: 0;
    transition: opacity 500ms ease-in;
    cursor: pointer;
    pointer-events: none;
}

.leftArrow {
    composes: hintArrow;
    left: 0;
    background: url(../../../../img/arrow-left-short2.svg) no-repeat -0.25rem 0rem, linear-gradient(-90deg, hsla(220, 50%, 20%, 0) 0%, hsla(220, 50%, 20%, 0.12) 110%);
    border-radius: 0 1rem 1rem 0;
    background-size: 2rem, 2rem;
}

.leftArrow:hover {
    background: url(../../../../img/arrow-left-short.svg) no-repeat -0.25rem 0rem, linear-gradient(-90deg, hsla(220, 50%, 20%, 0) 0%, hsla(220, 50%, 20%, 0.12) 110%);
    background-size: 2rem, 2rem;
}

.rightArrow {
    composes: hintArrow;
    right: 0;
    background: url(../../../../img/arrow-right-short2.svg) no-repeat 0.25rem 0rem, linear-gradient(90deg, hsla(220, 50%, 20%, 0) 0%, hsla(220, 50%, 20%, 0.12) 110%);
    border-radius: 1rem 0 0 1rem;
    background-size: 2rem, 2rem;
}

.rightArrow:hover {
    background: url(../../../../img/arrow-right-short.svg) no-repeat 0.25rem 0rem, linear-gradient(90deg, hsla(220, 50%, 20%, 0) 0%, hsla(220, 50%, 20%, 0.12) 110%);
    background-size: 2rem, 2rem;
}

.arrowVisible {
    opacity: 1;
    pointer-events: initial;
}



