.base {
    margin: 0;
	cursor: pointer;
	font-family: inherit;
}

.base:disabled {
    cursor: not-allowed;
}

.button {
	composes: base;
	display: inline-flex;
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: center;
	text-decoration: none;
	appearance: button;
	height: 2.5em;
	border-radius: .1875em;
	font-weight: 600;
	padding: 0 2em 0;
	text-shadow: 0 1px 1.5px rgba(0,0,0,.3);
	color: var(--black)
}

.sizeHuge {
	font-size: 1.5em;
}
.sizeBig {
	font-size: 1.25em;
}
.sizeNormal {
	font-size: 1em;
}
.sizeSmall {
	font-size: 0.75em;
}
.sizeTiny {
	font-size: 0.5em;
}

.colorPrimary {
	background: linear-gradient(180deg,#17bfcf,#1fa2ad);
	color: #f1f1f1;
}

.colorPrimary:hover,
.colorPrimary:focus-visible {
    background: linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
}

.colorSecondary {
	background: linear-gradient(180deg,#17bfcfB2,#1fa2adB2);
	color: #f1f1f1;
}
.colorSecondary:hover,
.colorSecondary:focus-visible {
    background: linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
}

.colorSuccess {
	background: green;
	color: #f1f1f1;
}
.colorSuccess:hover,
.colorSuccess:focus-visible{
    background: darkgreen;
}

.colorWarning {
	background: red;
	color: #f1f1f1;
}
.colorWarning:hover,
.colorWarning:focus-visible{
    background-color: darkred;
}

/* .styleContained {
	color: var(--color);
	background: var(--background);
	border: none;
}
.styleText {
	color: var(--background);
	background: #f1f1f1;
	border: none;
}
.styleOutline {
	color: var(--color);
	background: #f1f1f1;
	border-image: var(--background) 100%;
	border: 1px solid #222222;
} */