.closeButton {
    appearance: none;
    position: absolute;
    right: 0.125rem;
    top: 0.125rem;
    width: 2rem;
    height: 2rem;
    background: url('../../../img/x.svg') no-repeat center;
    background-size: 1.5rem;
    opacity: 0.5;
}

.closeButton:hover {
    cursor: pointer;
    background-size: 2rem;
}

.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.inputWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

form .inputWrapper > label {
    margin: 0;
    padding-left: 0;
    text-align: left;
}

form .inputWrapper  > label > span {
    margin: 0;
}

form input:invalid {
    border: 1px solid hsla(0, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(0, 80%, 50%, 0.25);
    transition: all var(--speed) ease-in;
}

.letOpNote {
    margin: 1rem 0 1rem 0;
}