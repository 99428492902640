.container {
    border-radius: 2px;
    border: 1px solid hsla(220, 50%, 20%, 0.3);
    padding: 0 0.5rem 0.125rem;
    width: 100%;
    height: 2.125rem;
    transition: all var(--speed) ease-in-out;
    position: relative;
}
.container:hover {
    cursor: pointer;
}
.container:focus,
.container:focus-visible{
    outline: none;
    border: 1px solid hsla(185, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(185, 80%, 50%, 0.25);
    background-color: #ffffff;
}

.overlay {
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
}

.overlayOpen {
    composes: overlay;
}

.overlayOpen::before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 0;
    z-index: 1;
    background: url(../../../img/caret-down-fill.svg) no-repeat;
    background-position: center;
    background-size: 1.25rem 1.25rem;
    transform: rotateX(180deg);
}

.overlayClosed {
    composes: overlay;
}

.overlayClosed::before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 0;
    z-index: 1;
    background: url(../../../img/caret-down-fill.svg) no-repeat;
    background-position: center;
    background-size: 1.25rem 1.25rem;
}

.menuContainer {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    line-height: 2rem;
}

.menu {
    width: 100%;
    position: absolute;
    display: block;
    /* 6 x height of one menu item */
    max-height: calc(6 * 2.5rem);
    min-height: 2.5rem;
    top: 110%;
    right: 0;
    border: 1px solid hsla(220, 50%, 20%, 0.3);
    overflow: scroll;
    background-color: #ffffff;
    z-index: 2;
}

.menuItem {
    padding:  0.125rem;
    background: #ffffff;
    height: 2.5rem;
    width: 100%;
    border: 1px solid transparent;
}

.menuItem:hover {
    background-color: hsla(220, 50%, 20%, 0.05);
}

.menuItemInput {
    composes: menuItem;
    position: sticky;
    top: 0;
}

.menuItemNotFound {
    composes: menuItem;
    color: #d2d6e1;
}

.menuItemHighlighted {
    composes: menuItem;
    background-color: hsla(220, 50%, 20%, 0.05);
}

.inputBox {
    height: 2rem;
}