.container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.input {
    width: 100%;
    border: solid hsla(220, 50%, 20%, 0.3) 1px;
    background-color: #ffffff;
    border-radius: 2px;
    font-size: 1rem;
    line-height: 2rem;
    height: 2rem;
    cursor: text;
    padding: 0 0.5rem 0.125rem;
}