.contractDataFiltersContainer {
    background-color: #ffffff;
    padding: 1.25rem 1.5625rem 0 1.3875rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    
}

.contractDataFiltersContainer h2 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    padding-left: 0.125rem;
}

.contractDataFilters{
    margin-left: 2rem;
    align-items: top;
    padding: 0.25rem 1rem 0.25rem 1rem;
    background-color: #ffffff;
    border-radius: 0.125rem;
    background-color: hsla(220, 50%, 20%, 0.025);
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    overflow-x: auto;
}

.contractDataFilters * {
    font-size: 0.875rem;
    line-height: 2rem;
}

.contractDataFilters select {
    padding-right: 1.5rem;
    padding-left: 0.5rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url(../../../img/caret-down-fill.svg) no-repeat right center;
    background-size: 1.125rem;
    cursor: default;
    width: 100%;
}

.contractDataFiltersElement {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 0.125rem;
    margin-right: 1rem;
}

.contractDataFiltersElement:last-of-type {
    margin-right: 0;
}

.contractDataFilters option {
    font-weight: 600;
}


.contractDataFilters label:last-of-type {
    margin-left: 0;
    margin-right: 0;
}