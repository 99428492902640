.sidebar {
    grid-column: 1/2;
    background-color: var(--white);
    position: fixed;
    width: 330px;
    top: 5rem;
    left: 0;
    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    z-index: 3;
}

.sidebar header {
    height: 5rem;
    text-align: center;
    position: relative;
}

.sidebar header h1 {
    color: var(--black);
    font-size: 1.375rem;
    line-height: 5rem;
}

.sidebar header .add {
    position: absolute;
    width: 2.25rem;
    height: 2.25rem;
    right: 0.5rem;
    top: 1.375rem;
    background: url(../img/person-plus.svg) no-repeat center center, linear-gradient(180deg, #17bfcf 0%, #1fa2ad 100%);
    background-size: 1.275rem, 2rem;
    border-radius: 0.1875rem;
    cursor: pointer;
}

.sidebar header .add:hover {
    background: url(../img/person-plus.svg) no-repeat center center, linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
    background-size: 1.275rem, 2rem;
}

.sidebar header .add.disabled {
    cursor: not-allowed;
}

.sidebar .search {
    position: relative;
    padding: 0 0.5rem 0.5rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.sidebar .search input {
    border-radius: 2px;
    font-size: 0.925rem;
    line-height: 2.25rem;
    flex-basis: 100%;
    padding: 0 0.625rem 0 2rem;
    transition: all var(--speed) ease-in-out;
    border: 1px solid hsla(220, 50%, 20%, 0.3);
    background-color: #ffffff;
    background: url('../img/search.svg') no-repeat 0.5rem center;
}

.sidebar .search input::placeholder {
    transition: opacity var(--speed) ease-in-out;
    opacity: 1;
    color: hsla(220, 50%, 20%, 0.4);
}

.sidebar .search input:focus::placeholder {
    opacity: 0;
}

.sidebar .search input:focus {
    border: 1px solid hsla(185, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(185, 80%, 50%, 0.25);
}

.sidebar .sort {
    position: relative;
    padding: 0 0.5rem 0.5rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.sidebar .sort button {
    border-radius: 2px;
    font-size: 0.925rem;
    line-height: 2.25rem;
    flex-basis: 100%;
    padding: 0 0.875rem;
    color: white;
    font-weight: 700;
    text-shadow: 0 1px 1.5px hsla(0, 0%, 0%, 0.3);
}

.sidebar .sort button {
    flex-basis: unset;
    cursor: pointer;
    opacity: 0.7;
}

.sidebar .sort button.name {
    background: url(../img/bar-chart-fill.svg) no-repeat 0.5rem center, linear-gradient(180deg, #17bfcf 0%, #1fa2ad 100%);
    padding-left: 2rem;
    background-size: 1.125rem, auto;
}

.sidebar .sort button.name:hover {
    background: url(../img/bar-chart-fill.svg) no-repeat 0.5rem center, linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
    padding-left: 2rem;
    background-size: 1.125rem, auto;
}

.sidebar .sort button.recent {
    background: url(../img/calendar4.svg) no-repeat 0.375rem 0.5rem, linear-gradient(180deg, #17bfcf 0%, #1fa2ad 100%);
    padding-left: 2rem;
    background-size: 1.1875rem, auto;
    position: relative;
}

.sidebar .sort button.recent:hover {
    background: url(../img/calendar4.svg) no-repeat 0.375rem 0.5rem, linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
    padding-left: 2rem;
    background-size: 1.1875rem, auto;
    position: relative;
}

.sidebar .sort button.recent aside {
    position: absolute;
    font-size: 0.6rem;
    left: 0.45rem;
    top: 0.55rem;
    font-weight: 700;
    letter-spacing: -0.5px;
    width: 1rem;
    text-align: center;
}

.sidebar .sort button.messages {
    background: url(../img/arrow-left-right.svg) no-repeat 0.5rem center, linear-gradient(180deg, #17bfcf 0%, #1fa2ad 100%);
    padding-left: 2rem;
    background-size: 1.25rem, auto;
}

.sidebar .sort button.messages:hover {
    background: url(../img/arrow-left-right.svg) no-repeat 0.5rem center, linear-gradient(180deg, #19d5e6 0%, #0ab3c2 100%);
    padding-left: 2rem;
    background-size: 1.25rem, auto;
}

.sidebar .sort button.selected {
    opacity: 1;
}

.sidebar .info {
    font-size: 0.825rem;
    padding: 0 0.5rem 0.5rem;
    color: var(--blue-100);
    opacity: 0.7;
}

.sidebar .list {
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar .list ul li.index {
    height: 1.625rem;
    line-height: 1.5rem;
    font-size: 0.875rem;
    padding-left: 0.775rem;
    border-bottom: 1px solid hsla(185, 40%, 40%, 0.2);
    font-weight: 700;
    background: linear-gradient(90deg, hsla(185, 80%, 50%, 0.15), hsla(185, 80%, 50%, 0));
    color: hsla(185, 60%, 30%, 1);
}

.sidebar .list ul li.index:hover {
    background: linear-gradient(90deg, hsla(185, 80%, 50%, 0.15), hsla(185, 80%, 50%, 0));
    cursor: default;
}

.sidebar .list ul li {
    height: 3rem;
    line-height: 3rem;
    padding-left: 2rem;
    padding-right: 0.25rem;
    cursor: pointer;
    transition: all var(--speed) ease-in-out;
    border: 1px solid white;
    background: url(../img/person.svg) no-repeat 0.5rem center;
    background-size: 1.25rem;
    display: flex;
    justify-content: space-between;
}

.sidebar .list ul li:hover {
    background: url(../img/person.svg) no-repeat 0.5rem center, linear-gradient(90deg, hsla(185, 80%, 50%, 0.2), hsla(185, 80%, 50%, 0.1));
    background-size: 1.25rem, auto;
}

.sidebar .list ul li p {
    line-height: 2.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
}

.collapse {
    position: fixed;
    width: 5px;
    top: 5rem;
    left: 330px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    background-color: hsla(220, 50%, 20%, 0.1);
    cursor: pointer;
    transition: background-color var(--speed) ease-in-out;
    z-index: 3;
}

.collapse:hover {
    background-color: hsla(220, 50%, 20%, 0.2);
}

.collapse button {
    box-shadow: 0 2px 3px hsla(220, 50%, 20%, 0.5);
    width: 1.25rem;
    height: 3rem;
    position: relative;
    left: 4px;
    border-radius: 2px;
    cursor: pointer;
    background: url(../img/arrow-left-short.svg) no-repeat center center, var(--white);
    background-size: 1.75rem;
}

.collapse:hover button:hover {
    background-size: 2rem;
}

.collapsed {
    left: -330px;
}

.handle {
    left: 0;
}

.collapse button.arrow {
    background: url(../img/arrow-right-short.svg) no-repeat center center, var(--white);
    background-size: 1.75rem;
    left: 8px;
    border-radius: 0px 2px 2px 0;
}

.list span {
    display: none;
    padding: 0.5rem 0.25rem 0 0;
}

.list span button {
    background-size: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 2px;
    cursor: pointer;
    transition: all var(--speed) ease-in-out;
}

.list span button.edit {
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    background: url(../img/pencil.svg) no-repeat center center, var(--white);
}

.list span button.archive {
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    background: url(../img/archive.svg) no-repeat center center, var(--white);
    margin-left: 4px;
}

.list span button:hover {
    background-size: 1.125rem;
}

.sidebar .list ul li:hover span {
    display: initial;
}

.current_client {
    font-weight: 600;
}
