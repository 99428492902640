.client-data {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 2rem;
}

.client-table {
    padding: 0.5rem 1rem 1rem;
    background-color: #ffffff;
    border-spacing: 0;
    white-space: nowrap;
    width: 100%;
    height: 100%;
}

.client-table--with-grid-2 {
    display: grid;
    flex-flow: initial;
    margin-top: 2rem;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 20px;
    align-items: start;
}

.flex50 {
    flex-basis: 50%;
}

.client-table tbody td:first-of-type {
    text-align: right;
    padding: 0.125rem 0.375rem 0.125rem 0;
    color: gray;
    width: 50%;
}

.client-table thead td:last-of-type,
.client-table tbody td:last-of-type {
    text-align: left;
    padding: 0.125rem 0 0.125rem 0.375rem;
}

.client-table tbody td label {
    color: #d2d6e1;
}

.data-table tbody td label {
    color: #d2d6e1;
}

.client-figure,
.signal-figure,
.message-figure,
.action-figure,
.in-progress-figure,
.history-figure,
.notification-figure,
.alloc-figure {
    min-height: 2rem;
    min-width: 2rem;
    opacity: 0.5;
}

.notification-figure {
    background: url('../img/bell2.svg') no-repeat center center;
    background-size: 2rem;
    margin-right: 0.25rem;
}

.client-figure {
    background: url('../img/person.svg') no-repeat center center;
    background-size: 2rem;
    margin-right: 0.25rem;
}

.signal-figure {
    background: url('../img/broadcast-pin.svg') no-repeat center center;
    background-size: 2rem;
    margin-right: 0.5rem;
}

.message-figure {
    background: url('../img/arrow-left-right-client.svg') no-repeat center center;
    background-size: 2rem;
    margin-right: 0.25rem;
}

.action-figure {
    background: url('../img/arrow-up-circle.svg') no-repeat center center;
    background-size: 2rem;
    margin-right: 0.5rem;
}

.in-progress-figure {
    background: url('../img/arrow-repeat.svg') no-repeat center center;
    background-size: 2rem;
    margin-right: 0.25rem;
    transform: rotateZ(135deg);
}

.history-figure {
    background: url('../img/clock-history.svg') no-repeat center center;
    background-size: 2rem;
    margin-right: 0.5rem;
}

.alloc-figure {
    background: url('../img/file-arrow-down-dark.svg') no-repeat center center;
    background-size: 2rem;
    margin-right: 0.5rem;
}

.data-table {
    width: 100%;
    border-spacing: 0;
    background-color: #ffffff;
    padding: 0.875rem 1rem;
    white-space: nowrap;
}

.data-table thead td {
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    vertical-align: top;
}

.data-table tbody tr:first-of-type td {
    padding: 0 1rem 0.25rem 0;
    border-bottom: 1px solid #d2d6e1;
    color: gray;
}

.data-table tbody tr:last-of-type td {
    padding: 0.125rem 1rem 0.25rem 0;
}

.data-table tbody tr:nth-of-type(2) td {
    padding: 0.25rem 1rem 0.25rem 0;
}

.data-table tbody tr:first-of-type td:hover {
    color: black;
}

.data-table tbody tr td:first-of-type {
    white-space: nowrap;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.data-table tbody tr td {
    padding: 0.125rem 1rem 0.125rem 0rem;
    cursor: pointer;
    position: relative;
    vertical-align: middle;
}

.data-table tbody tr td:last-of-type {
    padding-right: 0.5rem;
}

.data-table tbody tr:last-of-type td {
    border-bottom: 1px solid #d2d6e1;
}

.data-table tbody tr:hover {
    background-color: hsla(220, 50%, 20%, 0.05);
}

.data-table tbody tr:first-of-type:hover {
    background-color: inherit;
}

.data-table .highlight {
    background-color: hsla(220, 50%, 20%, 0.09);
}
.data-table .highlight:hover {
    background-color: hsla(220, 50%, 20%, 0.13);
}

.client-table .button,
.data-table .button {
    width: 2rem;
    height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    top: 2px;
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    background-color: #ffffff;
    margin-left: 4px;
    padding: 0;
}

.data-table .button.credit {
    background: url(../img/credit.svg) no-repeat center center;
    background-color: #ffffff;
    background-size: 1.125rem;
}

.data-table .button.credit[disabled] {
    cursor: default;
    pointer-events: none;
}

.client-table .button.info,
.data-table .button.info {
    background: url(../img/info.svg) no-repeat center center;
    background-size: 1.5rem;
    background-color: #ffffff;
}

.client-table .button.info[disabled],
.data-table .button.info[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.edit {
    background: url(../img/pencil.svg) no-repeat center center;
    background-color: #ffffff;
}

.data-table .button.edit[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.read {
    background: url(../img/eye.svg) no-repeat center center;
    background-color: #ffffff;
}

.data-table .button.source {
    background: url(../img/code.svg) no-repeat center center;
    background-color: #ffffff;
}

.data-table .button.pdf-export {
    background: url(../img/file-pdf.svg) no-repeat center center;
    background-size: 1.125rem;
    background-color: #ffffff;
}

.data-table .button.read[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.source[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.remove {
    background: url(../img/archive.svg) no-repeat center center;
    background-size: 1rem;
    background-color: #ffffff;
}

.data-table .button.remove[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.delete {
    background: url(../img/x.svg) no-repeat center center;
    background-size: 1.25rem;
    background-color: #ffffff;
}

.data-table .button.delete[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.history {
    background: url(../img/history.svg) no-repeat center center;
    background-size: 1.125rem;
    background-color: #ffffff;
}

.data-table .button.history[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.rate {
    background: url(../img/bar-chart.svg) no-repeat center center;
    background-size: 1.125rem;
    background-color: #ffffff;
}

.data-table .button.history[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.checkbox {
    background: url('../img/empty.svg') no-repeat center center;
    background-color: #ffffff;
}

.data-table .button.checked {
    background: url('../img/check.svg') no-repeat center center;
    background-color: #ffffff;
    background-size: 1.25rem;
}

.data-table .button.start {
    background: url(../img/play.svg) no-repeat center center;
    background-color: #ffffff;
    background-size: 1.25rem;
}

.data-table .button.start[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.stop {
    background: url(../img/stop.svg) no-repeat center center;
    background-color: #ffffff;
    background-size: 1.25rem;
}

.data-table .button.stop[disabled] {
    cursor: default;
    pointer-events: none;
}

.data-table .button.add {
    background: url(../img/plus.svg) no-repeat center center;
    background-color: #ffffff;
    background-size: 1.25rem;
}

.data-table .button.add[disabled] {
    cursor: not-allowed;
    pointer-events: none;
}

.data-table .button.send {
    background: url(../img/cloud-arrow-up.svg) no-repeat center center;
    background-size: 1.125rem;
    background-color: #ffffff;
}

.data-table .button.send[disabled] {
    cursor: default;
    pointer-events: none;
}

.client-table .button:hover,
.data-table .button:hover {
    background-size: 1.125rem;
}

.client-table .button.info:hover,
.data-table .button.info:hover {
    background-size: 1.75rem;
}

.data-table .button.credit:hover {
    background-size: 1.25rem;
}

.data-table .button.checked:hover {
    background-size: 1.375rem;
}

.data-table .button.remove:hover {
    background-size: 1.125rem;
}

.data-table .button.delete:hover {
    background-size: 1.3875rem;
}

.data-table .button.history:hover {
    background-size: 1.25rem;
}

.data-table .button.rate:hover {
    background-size: 1.25rem;
}

.data-table .button.send:hover {
    background-size: 1.25rem;
}

.data-table .button.start:hover {
    background-size: 1.3875rem;
}

.data-table .button.stop:hover {
    background-size: 1.3875rem;
}

.data-table .button.add:hover {
    background-size: 1.3875rem;
}

.data-table .button.remove[disabled]:hover {
    background-size: 1rem;
}

.client-table .button.info[disabled]:hover,
.data-table .button.info[disabled]:hover {
    background-size: 1.5rem;
}

.data-table .button.delete[disabled]:hover {
    background-size: 1.25rem;
}

.data-table .button.history[disabled]:hover {
    background-size: 1.125rem;
}

.data-table .button.rate[disabled]:hover {
    background-size: 1.125rem;
}

.data-table .button.send[disabled]:hover {
    background-size: 1.125rem;
}

.data-table .button.info[disabled]:hover {
    background-size: 1rem;
}

.data-table .button.edit[disabled]:hover {
    background-size: 1rem;
}

.data-table .button.read[disabled]:hover {
    background-size: 1rem;
}

.data-table .button.source[disabled]:hover {
    background-size: 1rem;
}

.data-table .button.start[disabled]:hover {
    background-size: 1.25rem;
}

.data-table .button.stop[disabled]:hover {
    background-size: 1.25rem;
}

.data-table .button.add[disabled]:hover {
    background-size: 1.25rem;
}

.data-table .col-check-btn {
    width: 1.5rem;
    height: 1rem;
    background: url('../img/check-all.svg') no-repeat center center;
    opacity: 0.3;
    background-size: 1.5rem;
    position: relative;
    top: 0.1875rem;
    left: 0.125rem;
    cursor: pointer;
    margin-left: 0.125rem;
}

.data-table .col-sort {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 0.15625rem;
    cursor: pointer;
}

.data-table .col-sort-asc {
    background: url('../img/arrow-up-short.svg') no-repeat center center;
    background-size: 1.5rem;
}

.data-table .col-sort-desc {
    background: url('../img/arrow-down-short.svg') no-repeat center center;
    background-size: 1.5rem;
}

.data-table .col-no-sort {
    background: url('../img/empty.svg') no-repeat center center;
    background-size: 1.5rem;
}

.table-header .add,
.table-footer .send,
.table-footer-top .send,
.btn-unhide {
    border: 1px solid #d2d6e1;
    background-color: #ffffff;
    line-height: 2rem;
    padding: 0 1rem 0.125rem 1rem;
    position: relative;
    margin-left: -1px;
    cursor: pointer;
    border-radius: 0.25rem;
}

.table-header .add:hover,
.table-footer .send:hover,
.table-footer-top .send:hover,
.btn-unhide:hover {
    background-color: hsla(0, 0%, 94%);
}

.table-header .add {
    border-radius: 2px;
    background: url(../img/plus.svg) no-repeat center center;
    background-size: 1.5rem;
    background-color: #ffffff;
    width: 2rem;
    height: 2rem;
    padding: 0;
    margin-left: 1.25rem;
}

.table-header .add:hover {
    background: url(../img/plus.svg) no-repeat center center;
    background-size: 1.75rem;
}

.table-footer .send[disabled],
.table-header .add[disabled],
.table-footer-top .send[disabled] {
    cursor: default;
}

.table-header .add[disabled]:hover {
    background-size: 1.5rem;
}

.table-footer .send[disabled]:hover,
.table-footer-top .send[disabled]:hover {
    background-color: #ffffff;
}

.table-scroll-x {
    overflow-x: auto;
}

.table-element {
    border: 5px solid #d2d6e1;
    background-color: #ffffff;
    border-radius: 0.25rem;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
}

.table-header {
    background-color: #ffffff;
    padding: 1.25rem 1.5625rem 0 1.3875rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.table-header h2 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    padding-left: 0.125rem;
}

.client-table .margin {
    height: 2rem;
}

.table-footer {
    background-color: #ffffff;
    padding: 0rem 1.5625rem 1rem;
}

.table-footer-top {
    background-color: #ffffff;
    padding: 1rem 1.5625rem 0rem;
}

.table-empty {
    background-color: #ffffff;
    padding: 1rem 1.5rem;
}

.table-empty span,
.table-footer span {
    display: block;
    color: gray;
}

.pb2 {
    padding-bottom: 2rem;
}

.hint {
    z-index: 1;
    position: absolute;
    right: 0;
    width: 2rem;
    top: 1.8125rem;
    bottom: 1.8125rem;
    background: url(../img/arrow-right-short2.svg) no-repeat 0.25rem 0rem, linear-gradient(90deg, hsla(220, 50%, 20%, 0) 0%, hsla(220, 50%, 20%, 0.12) 110%);
    background-size: 2rem, 2rem;
    opacity: 0;
    transition: opacity 500ms ease-in;
    cursor: pointer;
    pointer-events: none;
    border-radius: 1rem 0 0 1rem;
}

.left_hint {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 2rem;
    top: 1.8125rem;
    bottom: 1.8125rem;
    background: url(../img/arrow-left-short2.svg) no-repeat -0.25rem 0rem, linear-gradient(-90deg, hsla(220, 50%, 20%, 0) 0%, hsla(220, 50%, 20%, 0.12) 110%);
    background-size: 2rem, 2rem;
    opacity: 0;
    transition: opacity 500ms ease-in;
    cursor: pointer;
    pointer-events: none;
    border-radius: 0 1rem 1rem 0;
}

.hint.visible,
.left_hint.visible {
    opacity: 1;
    pointer-events: initial;
}

.hint:hover {
    background: url(../img/arrow-right-short.svg) no-repeat 0.25rem 0rem, linear-gradient(90deg, hsla(220, 50%, 20%, 0) 0%, hsla(220, 50%, 20%, 0.12) 110%);
    background-size: 2rem, 2rem;
}

.left_hint:hover {
    background: url(../img/arrow-left-short.svg) no-repeat -0.25rem 0rem, linear-gradient(-90deg, hsla(220, 50%, 20%, 0) 0%, hsla(220, 50%, 20%, 0.12) 110%);
    background-size: 2rem, 2rem;
}

.table-hidden {
    display: none;
}

.inner-table {
    background-color: hsla(220, 50%, 20%, 0.025);
    border-radius: 0.125rem;
    height: auto;
    width: auto;
    border-spacing: 0;
    white-space: nowrap;
    padding: 0.75rem 0.5rem 0.75rem;
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    margin-bottom: 0.25rem;
    margin-top: -0.125rem;
}

.inner-table tbody h3 {
    font-weight: 600;
    font-size: 1.25rem;
    color: black;
    padding-bottom: 0.75rem;
}

.inner-table tbody h3 span {
    font-weight: 400;
    font-size: 0.875rem;
    position: absolute;
    right: 0.375rem;
    top: 0.25rem;
    color: hsla(220, 50%, 20%, 0.5);
}

.inner-table-after {
    border-bottom: 1px solid #d2d6e1;
}

.filters {
    margin-left: 2rem;
    align-items: top;
    padding: 0.25rem 1rem 0.25rem 1rem;
    background-color: #ffffff;
    border-radius: 0.125rem;
    background-color: hsla(220, 50%, 20%, 0.025);
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    overflow-x: auto;
}

.filters * {
    font-size: 0.875rem;
    line-height: 2rem;
}

.filters select {
    padding-right: 1.5rem;
    padding-left: 0.5rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url(../img/caret-down-fill.svg) no-repeat right center;
    background-size: 1.125rem;
    cursor: default;
}

.filters select[disabled] {
    background: url(../img/caret-down-fill-disabled.svg) no-repeat right center;
}

.filters .checkbox {
    height: 1rem;
    width: 1rem;
    position: relative;
    top: 0.1875rem;
}

.filters label:last-of-type {
    margin-left: 0;
}

.filters label {
    margin-right: 0.5rem;
    display: inline-block;
}

.filters label:last-of-type {
    margin-right: 0;
}

.filters label.options {
    padding-left: 1.5rem;
}

.unread {
    font-weight: 600;
}

.table-in-form {
    margin: 0 2rem 1rem;
}

.filters .button {
    height: 2rem;
    border-radius: 0.125rem;
    cursor: pointer;
    margin-left: 4px;
    padding-left: 1.5rem;
}

.filters .button.download {
    background: url(../img/cloud-arrow-down.svg) no-repeat 0.125rem 0.4375rem;
    background-size: 1.125rem;
}

.filters .button.download[disabled] {
    cursor: default;
    pointer-events: none;
}
.filters .button.download:hover {
    background: url(../img/cloud-arrow-down.svg) no-repeat 0.0625rem 0.375rem;
    background-size: 1.25rem;
}

.filters .button.download[disabled]:hover {
    background: url(../img/cloud-arrow-down.svg) no-repeat 0.125rem 0.4375rem;
    background-size: 1.125rem;
}

.align-right {
    text-align: right;
}

.link.default {
    background: url(../img/cloud-arrow-down.svg) no-repeat 0 0.125rem;
    background-size: 1.125rem;
    padding-left: 1.5rem;
}

.link.xml {
    background: url(../img/cloud-arrow-down.svg) no-repeat 0 0.125rem;
    background-size: 1.125rem;
    padding-left: 1.5rem;
}

.link.json {
    background: url(../img/cloud-arrow-down.svg) no-repeat 0 0.125rem;
    background-size: 1.125rem;
    padding-left: 1.5rem;
}

.link.default[disabled],
.link.xml[disabled],
.link.json[disabled] {
    cursor: default;
    pointer-events: none;
}
.link.default:hover,
.link.xml:hover,
.link.json:hover {
    background: inherit no-repeat 0 0;
    background-size: 1.25rem;
    position: relative;
    left: -0.0625rem;
    padding-left: 1.5625rem;
}

.link.default[disabled]:hover,
.link.xml[disabled]:hover,
.link.json[disabled]:hover {
    background: inherit no-repeat 0 0;
    background-size: 1.125rem;
}

.pre-format span {
    display: block;
    font-size: 0.75rem;
    line-height: normal;
    margin-bottom: -0.0625rem;
}

.pre-format:last-child {
    margin-bottom: -0.75rem;
}
