@keyframes spinner {
    0% {
        transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
        transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
}

.regular::before {
    display: inline-block;
    border: 4px solid #cfd0d1;
    border-bottom-color: #1c87c0;
    border-radius: 50%;
    content: "";
    width: 2rem;
    height: 2rem;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    animation: spinner 1.5s infinite linear;
}

.container {
    position: relative;
    width: 100%;
    height: 5rem;
    background-color: transparent;
}