.container {
    width: 100%;
}
.titleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputContainerGrid {
    margin: 2rem;
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 0 1rem;
}