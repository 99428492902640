.input {
    border: solid hsla(220, 50%, 20%, 0.3) 1px;
    background-color: transparent;
    border-radius: 2px;
    line-height: 1rem;
    height: 2rem;
    cursor: text;
    padding: 0 0.5rem 0.125rem;
    margin-right: 0.5rem;
    width: auto;
}

.input:focus,
.input:focus-visible {
    border: 1px solid hsla(185, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(185, 80%, 50%, 0.25);
    transition: opacity var(--speed) ease-in-out;
}

.input:invalid {
    border: 1px solid hsla(0, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(0, 80%, 50%, 0.25);
}

.number {
    composes: input;
    width: 8rem;
}