.container {
    border: solid 1px;
    border-radius: 4px;
    min-height: 3rem;
    min-width: 5rem;
    width: auto;
    position: absolute;
    align-items: center;
    text-align: center;
    transition: all 500ms ease-in;
    bottom: 100%;
    transform: translate(-20%,-20%);
    z-index: 1;
    padding: 0.5rem;
}

.regular {
    composes: container;
    background-color: rgba(97, 97, 97, 0.92);
}

.regular-down {
    composes: container;
    top: 100%;
    bottom: initial;
    transform: translate(20%,20%);
}

.warning {
    composes: container;
    background-color: #721c24;
}

.warning-down {
    composes: container;
    background-color: #721c24;
    top: 100%;
    bottom: initial;
    transform: translate(-20, -20%);
}

.text {
    font-size: 1rem;
    color: white;
}
