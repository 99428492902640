.overlay {
    z-index: 999;
    margin: auto;
    border: none;
}

.overlay::backdrop {
    background-color: hsla(220, 50%, 20%, 0.7);
    z-index: 999;
}

.container {
    border-radius: 0.1875rem;
    background-color: var(--white);
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 60vw;
    min-width: 40vw;
    box-shadow: 0 1rem 2rem hsla(220, 50%, 20%, 1);
    overflow: auto;
    position: relative;
    border: 5px solid #d2d6e1;
}
