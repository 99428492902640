.title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.innerContainer {
    margin: 2rem;
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 0 1rem;
}

.label {
    font-size: 0.875rem;
    line-height: 2rem;
}