.base {
    height: 3rem;
}

.regular {
    composes: base;
}

.regular:hover {
    background-color: hsla(220, 50%, 20%, 0.05);
}

.fout {
    composes: base;
    background-color: #f5c6cb;
    position: relative;
}

.fout:hover {
    background-color: #f8d7da;
}