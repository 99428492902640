.tableBody {
    width: 100%;
}

.input {
    border: solid hsla(220, 50%, 20%, 0.3) 1px;
    background-color: transparent;
    border-radius: 2px;
    line-height: 1rem;
    height: 2rem;
    cursor: text;
    padding: 0 0.5rem 0.125rem;
    margin-right: 0.5rem;
    width: auto;
}

.input:focus,
.input:focus-visible {
    border: 1px solid hsla(185, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(185, 80%, 50%, 0.25);
    transition: opacity var(--speed) ease-in-out;
}

.input:invalid {
    border: 1px solid hsla(0, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(0, 80%, 50%, 0.25);
}

.number {
    composes: input;
    width: 8rem;
}

.statusLabel {
    border: solid 1px;
    border-radius: 4px;
    width: 7rem;
    color: white;
    text-align: center;
    transition: all var(--speed) ease-in-out;
}

.statusLabel p {
    font-size: 0.785rem;
}

.statusLabelOriginal {
    composes: statusLabel;
    background-color: green;
}
.statusLabelModified {
    composes: statusLabel;
    background-color: orange;
}
.statusLabelNewEntry {
    composes: statusLabel;
    background-color: blue;
}
.statusLabelArchived {
    composes: statusLabel;
    background-color: rgb(203, 99, 99);
}

.statusLabelEditing {
    composes: statusLabel;
    background-color: darkorange;
}

.statusLabelError {
    composes: statusLabel;
    background-color: red;
    box-shadow: 1px 1px 1rem #721c24, 1px 1px 1rem #721c24 ;
}

.statusLabelError:hover {
    box-shadow: initial;
    transition: all 500ms ease-out;
}

.statusLabelWrongValue {
    composes: statusLabel;
    background-color: darkred;
}

.button {
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    background-image: url('../../../../img/eye.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1rem;
    background-color: transparent;
    width: 2rem;
    height: 2rem;
    border-radius: 2px;
    cursor: pointer;
    transition: all var(--speed) ease-in-out;
}
.button:hover,
.button:focus,
.button:focus-visible {
    background-size: 1.25rem;
    border: 1px solid hsla(185, 70%, 40%, 0.6);
    box-shadow: 0 0 0 3px hsla(185, 80%, 50%, 0.25);
    transition: opacity var(--speed) ease-in-out;
}

.saveButton {
    composes: button;
    background: url('../../../../img/check.svg') center no-repeat;
}

.revertChangesButton {
    composes: button;
    background: url('../../../../img/arrow-counterclockwise.svg') center no-repeat;
}

.deleteButton {
    composes: button;
    background: url('../../../../img/trash.svg') center no-repeat;
}