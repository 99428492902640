.regular {
    appearance: none;
    width: 2rem;
    height: 2rem;
    max-width: 2rem;
    max-height: 2rem;
    border-radius: 2px;
    position: relative;
    top: 2px;
    border: 1px solid hsla(220, 50%, 20%, 0.2);
    background-color: #ffffff;
    margin-left: 4px;
    padding: 0;
    cursor: pointer;
}

.regular:checked {
    background: url('../../../img/check.svg') no-repeat center center;
    background-color: #ffffff;
    background-size: 1.25rem;;
}
