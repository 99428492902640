.regular {
    text-align: left;
    font-weight: normal;
    border-bottom: 1px solid #d2d6e1;
    color: gray;
    padding: 0 1rem;
}

.regular:last-of-type {
    padding: 0.125rem 1rem 0.25rem 0;
}

.indexCol {
    composes: regular;
    width: 2rem;
}

.colSort {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: 0.15625rem;
    background-size: 1.5;
    cursor: pointer;
}

.colSortNone {
    composes: colSort;
    background: url('../../../../../img/empty.svg') no-repeat center center;
}

.colSortAsc {
    composes: colSort;
    background: url('../../../../../img/arrow-up-short.svg') no-repeat center center;
}

.colSortDesc {
    composes: colSort;
    background: url('../../../../../img/arrow-down-short.svg') no-repeat center center;
}

.selectAllButton {
    width: 1.5rem;
    height: 1rem;
    background: url('../../../../../img/check-all.svg') no-repeat center center;
    opacity: 0.3;
    background-size: 1.5rem;
    position: relative;
    top: 0.1875rem;
    left: 0.125rem;
    cursor: pointer;
    margin-left: 0.125rem;
}

.selectAllButton:hover {
    cursor: pointer;
}