.base {
    min-width: 5rem;
    vertical-align: middle;
    width: auto;
    padding: 0 1rem;
}

.base:first-of-type {
    text-align: left;
}

.checkBoxCell {
    composes: base;
    width: 5rem;
}

.regularCell {
    composes: base;
    width: auto;
}

.indexCell {
    composes: base;
    min-width: 2rem;
}

.currencyCell {
    composes: base;
    width: 8rem;
}

.geenData {
    composes: base;
    color: #d2d6e1;
}

.foutCell {
    composes: base;
    position: relative;
}