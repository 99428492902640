.mainOuterContainer {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 2rem;
}

.mainInnerContainer {
    border: 5px solid #d2d6e1;
    background-color: #ffffff;
    border-radius: 0.25rem;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
}